import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  LandingPage,
  AccountPage,
  AppLinkPage,
  LegalPage,
  AppleAuthCallback
} from "@pages";
import {
  LoginScreen,
  ProfileScreen,
  ChangePasswordScreen,
  DeleteScreen,
  DeleteSuccessScreen,
  ForgotPasswordScreen,
} from "@screens";
import { StoreApp, setUserLanguage } from "@store";
import { checkUserLanguage } from "@utils";

const App = () => {
  const { userLoggedIn, hasAccountDeleted } = useSnapshot(StoreApp);

  useEffect(() => {
    setUserLanguage(checkUserLanguage());
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/">
          {/* Landing */}
          <Route index element={<LandingPage />} />

          {/* Account */}
          <Route path="account" element={<AccountPage />}>
            {!userLoggedIn ? (
              <>
                <Route index element={<LoginScreen />} />
                <Route path="forgot-pass" element={<ForgotPasswordScreen />} />
              </>
            ) : (
              <>
                <Route index element={<ProfileScreen />} />
                <Route path="change-pass" element={<ChangePasswordScreen />} />
                <Route
                  path="delete-account"
                  element={
                    !hasAccountDeleted ? <DeleteScreen /> : <DeleteSuccessScreen />
                  }
                />
              </>
            )};
            {/* Account redirect */}
            <Route path="*" exact element={<Navigate to="" />} />
          </Route>

          {/* Legal */}
          <Route path="/:language/privacy" element={<LegalPage datoID="193155936" />} />
          <Route path="/:language/terms" element={<LegalPage datoID="193155939" />} />

          {/* AppLink */}
          <Route path="applink" element={<AppLinkPage />} />

          {/* Apple Auth Callback */}
          <Route path="apple-auth-callback" element={<AppleAuthCallback />} />

          {/* Main redirect */}
          <Route path="*" exact element={<Navigate to="" />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;