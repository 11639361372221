import { LandingLayout } from "@layouts";
import { Hero } from "components/sections";

export const LandingPage = () => {
  return (
    <LandingLayout>
      <Hero />
    </ LandingLayout>
  );
};
