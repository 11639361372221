import { useState } from "react";
import { CtaButton, Checkbox, NavButton } from "@molecules";
import { deleteUser } from "@api";

const DeleteScreen = () => {
  const [formData, setFormData] = useState({
    checkbox01: false,
    checkbox02: false,
  });

  const handleInputChange = (name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: !prevFormData[name],
    }));
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    console.log("Form was submitted");
    console.log(formData);
    deleteUser();
  };

  return (
    <>
      <NavButton />
      <div className="account-screen">
        <div className="account-screen__head">
          <h3>Delete your account</h3>
          <p>
            Deleting your account is a permanent action, and all your data
            will be lost. Please consider the following before confirming:
          </p>
        </div>
        <form
          className="account-screen__form"
          onSubmit={onHandleSubmit}
        >
          <Checkbox
            name="checkbox01"
            label="I accept that all my personal information and health records will be permanently removed!"
            checked={formData.checkbox01}
            onChange={() => handleInputChange("checkbox01")}
          />
          <hr />
          <Checkbox
            name="checkbox02"
            label="I understand that i will no longer be able to log in using this account!"
            checked={formData.checkbox02}
            onChange={() => handleInputChange("checkbox02")}
          />
        </form>
        <div className="account-screen__actions">
          <CtaButton
            label="Delete my account"
            type="button"
            onClick={onHandleSubmit}
            variant="primary"
            disabled={!(formData.checkbox01 && formData.checkbox02)}
          />
        </div>
      </div>
    </>
  );
};

export { DeleteScreen };
