export const checkUserLanguage = () => {
  if (navigator.language) {
    const userLanguageShort = navigator.language.slice(0, 2);
    return ruleUserLanguage(userLanguageShort);
  }
};

export const ruleUserLanguage = (lang) => {
  if (lang === "en" || lang === "hu") {
    return lang;
  }
  else {
    return "en";
  }
};