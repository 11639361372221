import { cookieGetToken, urls } from "@api";
import { setUser } from "@store";

// GET user's profile data
export const getUser = async () => {
  try {
    const response = await fetch(urls.me, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookieGetToken(),
      },
    });

    if (response.ok) {
      const resBody = await response.json();

      // Set user data to store
      setUser(resBody);

    } else {
      console.error('Request failed with status:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};