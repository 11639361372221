import { cookieGetToken, urls } from "@api";

// PUT change user's password
export const resetPass = async (body) => {
  try {
    const response = await fetch(urls.resetPass, {
      method: "PUT",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: cookieGetToken(),
        password: body.password,
      }),
    });

    if (response.ok) {
      const resBody = await response.json();

      console.log(resBody);

    } else {
      console.error(`Request failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error(`Error: ${error}`);
  }
};
