import { useState } from "react";
import { CtaButton, Input, NavButton } from "@molecules";
import { resetPass } from "@api";

const ChangePasswordScreen = () => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/;
  const [formData, setFormData] = useState({
    password: "",
    passwordAgain: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    resetPass(formData);
  };

  return (
    <>
      <NavButton />
      <div className="account-screen subpage">
        <div className="account-screen__head">
          <h3>Change Password</h3>
          <p>
            Your new password should be at least eight characters long
            containing at least one capital letter, number and special
            character.
          </p>
        </div>
        <form
          className="account-screen__form"
          onSubmit={onHandleSubmit}
        >
          <Input
            inputValue={formData.password}
            onInputChange={handleInputChange}
            type="password"
            name="password"
            placeholder="New password"
            required
          />
          <Input
            inputValue={formData.passwordAgain}
            onInputChange={handleInputChange}
            type="password"
            name="passwordAgain"
            placeholder="New password again"
            required
          />
        </form>
        <div className="account-screen__actions">
          <CtaButton
            label="Change password"
            type="button"
            onClick={onHandleSubmit}
            variant="secondary"
            disabled={
              !passwordRegex.test(formData.password) ||
              formData.password !== formData.passwordAgain
            }
          />
        </div>
      </div>
    </>
  );
};

export { ChangePasswordScreen };
