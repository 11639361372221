import { Header, Footer } from '@partials';

const LegalLayout = ({ children }) => {

  return (
    <main className="legal">
      <Header />
      {children}
      <Footer light />
    </main>
  );
};

export { LegalLayout };
