export const loadFacebookSDK = () => {
  ((d, s, id) => {
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    js.onload = function() {
      window.FB.init({
        appId: process.env.REACT_APP_LOGIN_FACEBOOK_AID,
        xfbml: true,
        version: process.env.REACT_APP_LOGIN_FACEBOOK_GAV,
      });
    };
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
};