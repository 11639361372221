import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { LoginButton } from '@molecules';
import { postSocialLogin, urls } from "@api";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_LOGIN_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_LOGIN_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_LOGIN_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_LOGIN_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_LOGIN_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_LOGIN_FIREBASE_APPID,
};

const LoginButtonGoogle = () => {
  const app = initializeApp(firebaseConfig);
  const provider = new GoogleAuthProvider(app);
  const auth = getAuth();

  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      
      // Handle the response
      if (credential) {
        // Login with the received token
        postSocialLogin(urls.loginGoogle, credential.idToken);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LoginButton
      platform="google"
      onClick={handleSignInWithGoogle}
    />
  );
};

export { LoginButtonGoogle };