import { Footer, Header } from '@partials';

const AccountLayout = ({ children }) => {

  return (
    <main className="account">
      <Header />

      <div className="app-content">
        <div className="app-content__layout">
          {children}
        </div>
      </div>
      
      <Footer light account />
    </main>
  );
};

export { AccountLayout };
