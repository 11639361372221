import { cookieGetToken, getUser } from "@api";
import { useEffect } from "react";
import { AccountLayout } from "@layouts";
import { Outlet } from "react-router-dom";
import { StoreApp, setUserLoggedIn } from "@store";
import { useSnapshot } from "valtio";

export const AccountPage = () => {
  const { userLoggedIn } = useSnapshot(StoreApp);

  useEffect(() => {
    if (cookieGetToken()) {
      if (!userLoggedIn) setUserLoggedIn(true);
      else getUser();
    }
  }, [userLoggedIn]);

  return (
    <AccountLayout>
      <Outlet />
    </AccountLayout>
  );
};
