// Cookies Import
import Cookies from "universal-cookie";

// Initialize cookies
const cookies = new Cookies();

// Domain option
const domain = process.env.NODE_ENV === "development"
  ? "localhost"
  : ".perisyncapp.com";

// Token
export const cookieSetToken = (token) => cookies.set("userToken", token, { domain: domain });
export const cookieGetToken = () => cookies.get("userToken", { domain: domain });
export const cookieRemoveToken = () => cookies.remove("userToken", { domain: domain });
