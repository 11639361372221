import { useState } from "react";
import {
  Input,
  CtaButton,
  LinkButton,
  LoginButtonApple,
  LoginButtonGoogle,
  LoginButtonFacebook,
} from "@molecules";
import { postLogin } from "@api";

const LoginScreen = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    postLogin(formData);
  };

  return (
    <div className="login-screen">
      <div className="login-screen__head">
        <h3>Sign in with FHC</h3>
      </div>
      <form
        className="login-screen__form"
        onSubmit={onHandleSubmit}
      >
        <div className="login-screen__form__fields">
          <Input
            inputValue={formData.username}
            onInputChange={handleInputChange}
            type="text"
            name="email"
            placeholder="Email"
            required
          />
          <Input
            inputValue={formData.password}
            onInputChange={handleInputChange}
            type="password"
            name="password"
            placeholder="Password"
            required
          />
        </div>
        <div className="login-screen__form__actions">
          <CtaButton
            label="Login"
            type="submit"
            variant="primary"
          />
          <LinkButton
            label="Forgot password"
            to="forgot-pass"
          />
        </div>
      </form>

      <div className="login-screen__separator">
        <div className="login-screen__separator-line"/>
        <span>or sign in with</span>
        <div className="login-screen__separator-line"/>
      </div>

      <div className="login-screen__social">
        <LoginButtonFacebook />
        <LoginButtonGoogle />
        <LoginButtonApple />
      </div>

    </div>
  );
};

export { LoginScreen };
