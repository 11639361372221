import { Link } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "@icons";

const LinkButton = ({ label, to, backward }) => {
  return (
    <Link className="link-button" to={to}>
      {backward && <ArrowLeft color="coral"/>}
      {label}
      {!backward && <ArrowRight color="coral"/>}
    </Link>
  );
};

export { LinkButton };
