import { proxy } from "valtio";
import { cookieRemoveToken } from "@api";


// STATE
export const StoreApp = proxy({
  lang: "en",
  userLoggedIn: false,
  user: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
  },
  hasAccountDeleted: false,
});

// --- LANGUAGE ---
export const setUserLanguage = (value) => {
  StoreApp.lang = value;
};

// --- USER ---
// Set user logged in state
export const setUserLoggedIn = (value) => {
  StoreApp.userLoggedIn = value;
  if (!value) cookieRemoveToken();
};

// Set logged in user data
export const setUser = (data) => {
  // Keys that need to be stored
  const needToStore = ["id", "firstName", "lastName", "email"];
  // Store the values
  needToStore.forEach((key) => {
    StoreApp.user[key] = data[key];
  });
};

// Set account deletion state
export const setHasAccountDeleted = (value) => {
  StoreApp.hasAccountDeleted = value;
  if(value) cookieRemoveToken();
};
