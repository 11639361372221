import { Visibility } from "@icons";
import { useState } from "react";

const Input = ({
  inputValue,
  onInputChange,
  type = "text",
  name,
  placeholder,
  required
}) => {
  const [togglePassword, setTogglePassword] = useState(false);

  const showPassword = () => {
    setTogglePassword(!togglePassword);
  };

  return (
    <div className="input">
      <input
        name={name}
        type={type !== 'password' ? type : togglePassword ? 'text' : type}
        value={inputValue}
        onChange={onInputChange}
        placeholder={placeholder}
        required={required}
      />
      {type === "password" && (
        <button type="button" onClick={showPassword}>
          <Visibility />
        </button>
      )}
    </div>
  );
};

export { Input };
