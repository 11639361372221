// GET Legal document data from DatoCMS
export const getLegal = async (datoID, language) => {
  console.log(language);

  try {
    const response = await fetch(`https://graphql.datocms.com/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_DATO_TOKEN}`,
      },
      body: JSON.stringify({
        query: `
          {
            allLegalDocuments(locale: ${language}) {
              id
              title
              description
            }
          }
        `,
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const res = await response.json();
    
    // Find the item with the specified id
    const itemWithID = res.data.allLegalDocuments.find(item => item.id === datoID);

    // Return error if no items found with the specified id
    if (!itemWithID) {
      throw new Error(`Legal document with id ${datoID} not found`);
    }
    // Return found item
    return itemWithID;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};