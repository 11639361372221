import { Link } from "react-router-dom";
import { Container } from "./container";
import { StoreApp } from "@store";
import { useSnapshot } from "valtio";

const Footer = ({light, account}) => {
  const currentYear = new Date().getFullYear();
  const {lang} = useSnapshot(StoreApp);

  return (
    <footer className={`app-footer ${light ? "light" : ""}`}>
      <Container>
        <div className="app-footer__content">
          <span>{currentYear} © Forhercare Kft. All rights reserved.</span>
          <div className="app-footer__content-links">
            {!account && 
              <>
                <Link to="/account">
                  <span className="bold">Manage your Account</span>
                </Link>
                <hr/>
              </>
            }
            <Link to={`/${lang}/privacy`}>
              <span className="bold">Privacy</span>
            </Link>
            <hr/>
            <Link to={`/${lang}/terms`}>
              <span className="bold">T&C</span>
            </Link>
            <hr/>
            <a href="https://forhercare.com" target="_blank" rel="noreferrer">
              <span className="bold">FHC</span>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export { Footer };
