import { useSnapshot } from "valtio";
import { MenuButton } from "@molecules";
import { StoreApp, setUserLoggedIn } from "@store";

const ProfileScreen = () => {
  const { user } = useSnapshot(StoreApp);

  return (
    <div className="account-screen">
      <div className="account-screen__user">
        <h3>{user.firstName} {user.lastName}</h3>
        <p>{user.email}</p>
      </div>
      <div className="account-screen__menu">
        <MenuButton
          type="link"
          action="/account/change-pass"
          label="Change password"
        />
        <MenuButton
          type="link"
          action="/account/delete-account"
          label="Delete your account"
        />
        <MenuButton
          type="action"
          action={() => setUserLoggedIn(false)}
          label="Log out"
        />
      </div>
    </div>
  );
};

export { ProfileScreen };
