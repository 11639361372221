import { useEffect } from "react";

const appStoreLink = process.env.REACT_APP_APPSTORE_LINK;
const googlePlayStoreLink = process.env.REACT_APP_GOOGLEPLAY_LINK;

export const AppLinkPage = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);

  useEffect(() => {
    // Redirect to AppStore if IOS
    if (isIOS) {
      window.location.assign(appStoreLink);
    }
    // Redirect to GooglePlay if Android
    else if (isAndroid) {
      window.location.assign(googlePlayStoreLink);
    }
    // Redirect to Root if none of the above
    else {
      window.location.assign("/");
    }
  }, []);

  return (
    <main className="redirect">
      <h3>Redirecting...</h3>
    </main>
  );
};
