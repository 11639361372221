import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "@icons";

const NavButton = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);

  return (
    <button
      className="nav-button"
      onClick={handleBack}
      type="button"
    >
      <ArrowLeft />
      <p className="bold">Go back</p>
    </button>
  );
};

export { NavButton };
