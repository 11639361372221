import { useEffect } from 'react';
import { LoginButton } from '@molecules';
import { loadSignInWithApple } from '@utils';

const LoginButtonApple = () => {

  useEffect(() => {
    loadSignInWithApple();
  }, []);

  const handleLogin = () => {
    const { AppleID } = window;

    if (AppleID) {
      // Initialize AppleID
      AppleID.auth.init({
        clientId: process.env.REACT_APP_LOGIN_APPLE_CID,
        scope: 'name email',
        redirectURI: process.env.REACT_APP_LOGIN_APPLE_REDIRECTURI,
        state: '',
        usePopup: true,
      });
      // Sign in with Apple ID
      AppleID.auth.signIn();
    }
  };

  return (
    <LoginButton
      platform="apple"
      onClick={handleLogin}
    />
  );
};

export { LoginButtonApple };