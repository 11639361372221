import { useState } from "react";
import { Input, CtaButton, LinkButton } from "@molecules";
import { EmailSent } from "@icons";
import { forgotPass } from "@api";

const ForgotPasswordScreen = () => {
  const [hasPasswordReseted, setPasswordReseted] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    setPasswordReseted(forgotPass(formData));
  };

  return (
    <div className="login-screen">
      {!hasPasswordReseted
        ? (
          <>
            <div className="login-screen__head">
              <h3>Forgot Password</h3>
              <p>It happens to the best of us! No worries, we have got you covered. To reset your password, please enter your email address below.</p>
            </div>
            <form
              className="login-screen__form"
              onSubmit={onHandleSubmit}
            >
              <div className="login-screen__form__fields">
                <Input
                  inputValue={formData.email}
                  onInputChange={handleInputChange}
                  type="text"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="login-screen__form__actions">
                <CtaButton
                  label="Reset password"
                  type="submit"
                  variant="primary"
                />
                <LinkButton
                  label="Cancel"
                  to="/account"
                  backward
                />
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="login-screen__icon">
              <EmailSent color="coral"/>
            </div>
            <div className="login-screen__head">
              <h3>Check your inbox</h3>
              <p>We have sent you an email with instructions on how to reset your password.</p>
            </div>
            <div className="login-screen__form__actions">
              <LinkButton
                label="Go back to login"
                to="/account"
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export { ForgotPasswordScreen };
