import { Link } from 'react-router-dom';
import { ArrowRight } from "@icons";

const MenuButton = ({ type, action, label }) => {
  return (
    <MenuButtonWrapper
      type={type}
      action={action}
    >
      <p className="bold">{label}</p>
      <ArrowRight />
    </MenuButtonWrapper>
  );
};

const MenuButtonWrapper = ({type, action, children}) => {
  const buttonProps = {
    className: 'menu-button',
  };

  if (type === 'link') {
    return (
      <Link {...buttonProps} to={action}>
        {children}
      </Link>
    );
  } else {
    return (
      <button {...buttonProps} type="button" onClick={action}>
        {children}
      </button>
    );
  }
};

export { MenuButton };
