import { Apple, Facebook, Google } from "@icons";

const LoginButton = ({ platform, onClick }) => {
  const socialIcons = {
    facebook: <Facebook color="white" />,
    google: <Google color="white" />,
    apple: <Apple color="white" />,
  };

  return (
    <button className={`social-button ${platform}`} onClick={onClick} type="button">
      {socialIcons[platform]}
    </button>
  );
};

export { LoginButton };
