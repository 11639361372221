const CtaButton = ({ label, onClick, type, variant = "primary", disabled }) => {
  return (
    <button
      className={`cta-button ${variant ?? ""}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export { CtaButton };
