import { cookieSetToken } from "@api";
import { setUserLoggedIn } from "@store";

// POST Social Login (Facebook, Google, Apple)
export const postSocialLogin = async (url, token) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    });

    if (response.ok) {
      const resBody = await response.json();

      // Set userLoggedIn
      setUserLoggedIn(true);

      // Store token at cookie
      cookieSetToken(resBody.token);
    } else {
      console.error('Request failed with status:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};