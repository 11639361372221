import { cookieSetToken, urls } from "@api";
import { setUserLoggedIn } from "@store";

// POST Login
export const postLogin = async (body) => {
  try {
    const response = await fetch(urls.login, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: body.email,
        password: body.password,
      }),
    });

    if (response.ok) {
      const resBody = await response.json();

      // Set userLoggedIn
      setUserLoggedIn(true);

      // Store token at cookie
      cookieSetToken(resBody.token);
      
    } else {
      console.error('Request failed with status:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};