import { Footer } from '@partials';

const LandingLayout = ({ children }) => {

  return (
    <main className="landing">
      {children}
      <Footer />
    </main>
  );
};

export { LandingLayout };