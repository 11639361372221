import { useEffect } from 'react';
import { postSocialLogin, urls } from '@api';

export const AppleAuthCallback = () => {
  
  useEffect(() => {
    // Parse the query parameters from the URL
    const params = new URLSearchParams(window.location.search);

    // Check for the presence of authorization code and ID token
    const authorizationCode = params.get('code');
    const idToken = params.get('id_token');

    if (authorizationCode && idToken) {
      console.log(authorizationCode, idToken);

      // Login with the received token
      postSocialLogin(urls.loginApple, idToken);
    } else {
      console.error('Authorization code or ID token not present in the callback URL.');
    }
  }, []);

  return (
    <main className="redirect">
      <h3>Handling authentication...</h3>
    </main>
  );
};