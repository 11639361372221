import { urls } from "@api";

// POST password reset
export const forgotPass = async (body) => {
  try {
    const response = await fetch(urls.forgotPass, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: body.email,
      }),
    });

    if (response.ok) {
      return true;
    } else {
      console.error(`Request failed with status: ${response.status}`);
      return false;
    }

  } catch (error) {
    console.error(`Error: ${error}`);
    return false;
  }
};