import { useEffect } from "react";
import { loadFacebookSDK } from "@utils";
import { LoginButton } from "@molecules";
import { postSocialLogin, urls } from "@api";

const LoginButtonFacebook = () => {

  useEffect(() => {
    loadFacebookSDK();
  }, []);

  const handleLogin = () => {
    // Ensure the SDK is loaded
    if (window.FB) {
      window.FB.login(function(response) {
        console.log(response);
        
        // Handle the response
        if (response.authResponse) {
          // Login with the received token
          postSocialLogin(urls.loginFacebook, response.authResponse.accessToken);
        }
      });
    }
  };

  return (
    <LoginButton
      platform="facebook"
      onClick={handleLogin}
    />
  );
};

export { LoginButtonFacebook };
