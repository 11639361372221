import { Checked } from "@icons";
import { useState } from "react";

const Checkbox = ({ label, checked, onChange, name }) => {
  const [isChecked, setChecked] = useState(checked);

  const handleCheckboxChange = () => {
    const updatedChecked = !isChecked;
    setChecked(updatedChecked);
    onChange(updatedChecked);
  };

  return (
    <div className="checkbox">
      <label>
        <input
          name={name}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {isChecked ? (
          <span className={`checkbox__checkmark active`} aria-hidden="true">
            <Checked color="white"/>
          </span>
        ) : (
          <span className={`checkbox__checkmark`} aria-hidden="true" />
        )}
        <span className="checkbox__label">{label}</span>
      </label>
    </div>
  );
};

export { Checkbox };
