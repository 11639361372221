import { cookieGetToken, urls } from "@api";
import { setHasAccountDeleted } from "@store";

// DELETE users's account
export const deleteUser = async () => {
  try {
    const response = await fetch(urls.me, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookieGetToken(),
      },
    });

    if (response.ok) {
      setHasAccountDeleted(true);
    } else {
      console.error('Request failed with status:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};