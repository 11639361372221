import { CtaButton } from "@molecules";
import { Delete } from "@icons";
import { setUserLoggedIn } from "@store";

const DeleteSuccessScreen = () => {
  return (
    <div className="account-screen">
      <div className="account-screen__message">
        <div className="account-screen__message-icon">
          <Delete color="coral"/>
        </div>
        <div className="account-screen__message-content">
          <h3>Account deleted</h3>
          <p>
            Thank you for using PeriSync. If you ever decide to return, you
            will need to create a new account.
          </p>
        </div>
      </div>
      <CtaButton
        label="Return to home"
        type="button"
        onClick={() => setUserLoggedIn(false)}
        variant="secondary"
      />
    </div>
  );
};

export { DeleteSuccessScreen };
