import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { LegalLayout } from "@layouts";
import { getLegal } from "@api";
import { useParams } from "react-router-dom";
import { ruleUserLanguage } from "@utils";

export const LegalPage = ({datoID}) => {
  const {language} = useParams();
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLegal(datoID, ruleUserLanguage(language));
        setContent(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
  }, [datoID, language]);

  return (
    <LegalLayout>
      <div className="app-content">
        <h2>{content.title}</h2>
        <hr/>
        <div className="app-content__body">
          <ReactMarkdown>
            {content.description}
          </ReactMarkdown>
        </div>
      </div>
    </ LegalLayout>
  );
};
